<template>
  <div class="bottom-container">
    <a-tabs v-model="activePane">
      <a-tab-pane key="1" tab="待审核">
        <one-table v-if="activePane == 1"></one-table>
      </a-tab-pane>
      <a-tab-pane key="2" tab="已退回" force-render>
        <two-table v-if="activePane == 2"></two-table>
      </a-tab-pane>
      <a-tab-pane key="3" tab="已上架">
        <three-table v-if="activePane == 3" />
      </a-tab-pane>
      <a-tab-pane key="4" tab="已禁用">
        <four-table v-if="activePane == 4"></four-table
      ></a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import OneTable from "./component/oneTable.vue";
import TwoTable from "./component/twoTable.vue";
import ThreeTable from "./component/threeTable.vue";
import FourTable from "./component/fourTable.vue";
export default {
  data() {
    return {
      activePane: "1",
    };
  },
  methods: {
    callback() {},
  },
  created() {},
  components: {
    OneTable,
    ThreeTable,
    TwoTable,
    FourTable,
  },
};
</script>
