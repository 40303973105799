<template>
  <div>
    <div class="search-box">
      <div class="lable-box search-item">
        <label> 商品名称：</label>
        <a-input v-model="searchText.goodsNameCn" placeholder="请输入" />
      </div>
      <div class="lable-box search-item" style="height: 32px">
        <label> 商品分类：</label>
        <selectTree
          ref="selectThre"
          :treeData="treeData"
          v-model="searchText.goodsCategoryId"
        />
      </div>
      <div class="lable-box search-item">
        <label>工厂名称：</label>
        <a-input v-model="searchText.factoryName" placeholder="请输入" />
      </div>
      <div>
        <a-button @click="resetData" class="button-item"> 重置 </a-button>
        <a-button @click="search" class="button-item"> 查询 </a-button>
      </div>
    </div>

    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      :scroll="{ y: 460 }"
      :loading="loading"
    >
      <template slot="operation" slot-scope="text, record">
        <span
          style="color: rgb(255, 70, 0); cursor: pointer"
          @click="handleDisable(record)"
        >
          禁用
        </span>
        <span
          style="color: rgb(255, 70, 0); cursor: pointer"
          @click="handleDetail(record)"
        >
          查看
        </span>
        <span
          style="color: rgb(255, 70, 0); cursor: pointer"
          @click="handleExport(record.goodsId)"
        >
          导出
        </span>
      </template>
    </a-table>
    <add-table-model :config="editConfig" v-if="editConfig.visible">
      <template #titleText>
        <h3>审核意见：</h3>
        <max-texarea
          :disabled="true"
          :maxLength="128"
          v-model="reviewDescription"
          placeholder="请输入内容"
        />
        <h3 style="margin-top: 1rem">是否检测：</h3>
        <a-radio-group v-model="isDetect" disabled>
          <a-radio :value="true"> 是 </a-radio>
          <a-radio :value="false"> 否 </a-radio>
        </a-radio-group>
      </template>
      <template #footer>
        <div style="text-align: right">
          <a-button
            @click="editConfig.visible = false"
            style="margin-left: 10px"
            >关闭</a-button
          >
        </div>
      </template>
    </add-table-model>
    <confimModel :config="confirmConfig" @compile="getBaseData" />
  </div>
</template>

<script>
import AddTableModel from "@/components/addTableModel/addTableModel.vue";
import { setKey } from "@/utils/utils.js";
import { SelectionGoods } from "@/api/SelectionGoods.js";
import pagination from "@/mixins/Pagination.js";
import confimModel from "@/components/confimModel/index.vue";
import { shelfApplicaAPi } from "@/api/ShelfApplication.js";
import MaxTexarea from "@/components/max-texarea/max-texarea.vue";
import selectTree from "@/components/selectTree";
import { tips } from "../../../utils/tips";
const columns = [
  {
    dataIndex: "goodsNameCn",
    key: "goodsNameCn",
    title: "商品名称",
    ellipsis: true,
    width: 200,
  },
  {
    title: "商品分类",
    dataIndex: "goodsCategoryName",
    key: "goodsCategoryName",
  },
  {
    title: "申请时间",
    dataIndex: "createTime",
    key: "createTime",
  },
  {
    title: "基础价",
    dataIndex: "onPrice",
  },
  {
    title: "定制起订量",
    dataIndex: "minOrder",
  },
  {
    title: "可售区域",
    dataIndex: "availableArea",
    ellipsis: true,
    width: 200,
  },
  {
    title: "工厂名称",
    dataIndex: "factoryName",
  },
  {
    title: "操作",
    scopedSlots: { customRender: "operation" },
  },
];

const data = [];

export default {
  mixins: [pagination],
  name: "threeTable",
  data() {
    return {
      data,
      columns,
      searchText: {
        goodsCategoryId: "",
        goodsNameCn: "",
        factoryName: "",
      },
      confirmConfig: {},
      editConfig: {},
      treeData: [],
      loading: false,
      reviewDescription: "",
      isDetect: false, // 是否检测
    };
  },
  components: {
    AddTableModel,
    confimModel,
    MaxTexarea,
    selectTree,
  },
  methods: {
    async getBaseData() {
      this.loading = true;
      let baseParmase = {
        page: this.pagination.current,
        size: this.pagination.pageSize,
        state: 0, //上架
        ...this.searchText,
      };
      try {
        let res = await SelectionGoods.getBaseData(baseParmase);
        if (this.pagination.current > 1 && res.data.records.length == 0) {
          this.pagination.current -= 1;
          this.getBaseData();
        }
        this.pagination.total = res.data.total;
        this.data = setKey(res.data.records);
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    onSizeChange(current, size) {
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.getBaseData();
    },
    onPage(e) {
      this.pagination.current = e;
      this.getBaseData();
    },
    resetData() {
      this.searchText = {
        goodsCategoryId: "",
        goodsNameCn: "",
        factoryName: "",
      };
      this.$refs.selectThre.reset();
      this.getBaseData();
    },
    search() {
      this.pagination.page = 1;
      this.getBaseData();
    },
    async getCateDate() {
      let res = await SelectionGoods.getCateGoryData();
      if (res.success) {
        this.addParams(res.data);
        this.treeData = res.data;
      }
    },
    addParams(arr) {
      arr.forEach((v) => {
        v.key = v.id;
        v.title = v.label;
        v.value = v.id;
        if (v.children && v.children.length) {
          this.addParams(v.children);
        }
      });
    },
    handleDisable(data) {
      let obj = {
        url: `/mall/factory/forbidden/${data.goodsId}`,
        visible: true,
        method: "put",
        title: `确认要禁用“${data.goodsNameCn}”吗？`,
        message: "禁用成功",
      };
      this.confirmConfig = obj;
    },
    async handleDetail(data) {
      let target = {
        GoodsId: data.goodsId,
      };
      let res = await shelfApplicaAPi.geyEditData(target);
      this.isDetect = res.data.isDetect;
      this.reviewDescription = res.data.reviewDescription;
      if (res.success) {
        let obj = {
          visible: true,
          data: res.data,
          type: "uninput",
          title: "详情",
        };
        this.editConfig = obj;
      }
    },
    changeData() {},
    // 处理导出
    handleExport(id) {
      let goodIdList = [id];
      shelfApplicaAPi.exportGoods(goodIdList).then((res) => {
        if (res.success) {
          tips(res, "导出");
        }
        const link = document.createElement("a");
        //对文件名乱码转义--【Node.js】使用iconv-lite解决中文乱码
        let blob = new Blob([res.data], {
          type: "application/msword",
        });
        let s = res.headers["content-disposition"];
        let x = s.slice(s.indexOf("=") + 1);
        let fileName = decodeURI(decodeURIComponent(escape(x)), "UTF-8");
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);

        console.log("blobx", URL.createObjectURL(blob));
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.exportLoading = false;
      });
    },
  },
  mounted() {
    this.getBaseData();
    this.getCateDate();
  },
};
</script>

<style lang="scss" scoped>
.search-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .search-item {
    width: 27%;
    text-align: right;
  }
  .button-item {
    margin-left: 10px;
    &:nth-child(2) {
      background-color: #ff4600;
      color: #fff;
    }
  }
}
.ant-table-wrapper {
  margin-top: 30px;
}
</style>
