<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      :scroll="{ y: 460 }"
      :loading="loading"
    >
      <span
        slot="operation"
        style="color: rgb(255, 70, 0); cursor: pointer"
        @click="handleOperation(record)"
        slot-scope="text, record"
      >
        审核
      </span>
      <span slot="createTime" slot-scope="text">
        {{ text }}
      </span>
    </a-table>
    <add-table-model :config="examine" v-if="examine.visible">
      <template #titleText>
        <h3>审核意见：</h3>
        <max-texarea
          :maxLength="128"
          v-model="reviewDescription"
          placeholder="请输入内容"
        />
        <h3 style="margin-top: 1rem">是否检测：</h3>
        <a-radio-group v-model="isDetect">
          <a-radio :value="true"> 是 </a-radio>
          <a-radio :value="false"> 否 </a-radio>
        </a-radio-group>
      </template>
      <template #footer>
        <div style="text-align: right">
          <a-button @click="closeAdd" style="margin-left: 10px">返回</a-button>
          <a-popconfirm
            title="是否确定退回?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="confirm(3)"
          >
            <a-button style="margin-left: 10px">退回</a-button>
          </a-popconfirm>
          <a-popconfirm
            title="是否确定提交?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="confirm(0)"
          >
            <a-button class="xp-btn-color" style="margin-left: 10px"
              >提交</a-button
            >
          </a-popconfirm>
        </div>
      </template>
    </add-table-model>
  </div>
</template>
<script>
import AddTableModel from "@/components/addTableModel/addTableModel.vue";
import MaxTexarea from "@/components/max-texarea/max-texarea.vue";
import { setKey } from "@/utils/utils.js";
import { SelectionGoods } from "@/api/SelectionGoods.js";
import pagination from "@/mixins/Pagination.js";
import { tips } from "@/utils/tips";
const columns = [
  {
    dataIndex: "goodsNameCn",
    ellipsis: true,
    key: "goodsNameCn",
    title: "商品名称",
  },
  {
    title: "商品分类",
    dataIndex: "goodsCategoryName",
    ellipsis: true,
    key: "goodsCategoryName",
  },
  {
    title: "申请时间",
    dataIndex: "createTime",
    ellipsis: true,
    key: "createTime",
    scopedSlots: { customRender: "createTime" },
  },
  {
    title: "基础价",
    dataIndex: "onPrice",
    ellipsis: true,
  },
  {
    title: "定制起订量",
    dataIndex: "minOrder",
    ellipsis: true,
  },
  {
    title: "可售区域",
    dataIndex: "availableArea",
    ellipsis: true,
  },
  {
    title: "工厂名称",
    dataIndex: "factoryName",
    ellipsis: true,
  },
  {
    title: "操作",
    scopedSlots: { customRender: "operation" },
  },
];

const data = [
  // {
  //   key: "1",
  //   name: "John Brown",
  //   age: 32,
  //   address: "New York No. 1 Lake Park",
  //   tags: ["nice", "developer"],
  // },
];
export default {
  mixins: [pagination],
  data() {
    return {
      data,
      columns,
      examine: {
        visible: false,
        title: "审核",
      },
      loading: false,
      GoodsId: "",
      reviewDescription: "",
      isDetect: false, // 是否检测
    };
  },
  components: {
    AddTableModel,
    MaxTexarea,
  },
  methods: {
    async handleOperation(data) {
      let target = {
        GoodsId: data.goodsId,
      };
      this.GoodsId = data.goodsId;
      let res = await SelectionGoods.getEditData(target);
      if (res.success) {
        this.examine.visible = true;
        this.examine.data = res.data;
        this.examine.type = "uninput";
      }
    },
    closeAdd() {
      this.examine.visible = false;
    },
    async confirm(state) {
      if (state === 3 && this.reviewDescription === "") {
        this.$message.error("审核意见不能为空");
        return;
      } else {
        let data = {
          goodsId: this.GoodsId,
          reviewDescription: this.reviewDescription,
          isDetect: this.isDetect,
          state,
        };
        let res = await SelectionGoods.examineData(data);
        let msg = state === 0 ? "提交" : "退回";
        tips(res, msg);
        if (res.success) {
          this.examine.visible = false;
          this.getBaseData();
        }
      }
    },
    async getBaseData() {
      this.loading = true;
      let baseParmase = {
        page: this.pagination.current,
        size: this.pagination.pageSize,
        state: "4", //待审核
      };
      try {
        let res = await SelectionGoods.getBaseData(baseParmase);
        if (this.pagination.current > 1 && res.data.records.length == 0) {
          this.pagination.current -= 1;
          this.getBaseData();
        }
        this.pagination.total = res.data.total;
        this.data = setKey(res.data.records, "goodsId");
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    onSizeChange(current, size) {
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.getBaseData();
    },
    onPage(e) {
      this.pagination.current = e;
      this.getBaseData();
    },
  },
  mounted() {
    this.getBaseData();
  },
};
</script>
