<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      :loading="loading"
      :scroll="{ y: 460 }"
    >
      <div slot="operation" slot-scope="text, record">
        <span
          style="color: rgb(255, 70, 0); cursor: pointer"
          @click="handleOk(record)"
        >
          启用
        </span>
        <span
          style="color: rgb(255, 70, 0); cursor: pointer"
          @click="handleOperation(record)"
        >
          查看
        </span>
      </div>
    </a-table>
    <add-table-model :config="examine" v-if="examine.visible">
      <template #titleText>
        <h3>审核意见：</h3>
        <max-texarea
          :maxLength="128"
          :disabled="true"
          v-model="reviewDescription"
          placeholder="请输入内容"
        />
        <h3 style="margin-top: 1rem">是否检测：</h3>
        <a-radio-group v-model="isDetect" disabled>
          <a-radio :value="true"> 是 </a-radio>
          <a-radio :value="false"> 否 </a-radio>
        </a-radio-group>
      </template>
      <template #footer>
        <div style="text-align: right">
          <a-button @click="examine.visible = false" style="margin-left: 10px"
            >返回</a-button
          >
        </div>
      </template>
    </add-table-model>
    <confimModel :config="confirmConfig" @compile="getBaseData" />
  </div>
</template>
<script>
import AddTableModel from "@/components/addTableModel/addTableModel.vue";
import MaxTexarea from "@/components/max-texarea/max-texarea.vue";
import { setKey } from "@/utils/utils.js";
import { SelectionGoods } from "@/api/SelectionGoods.js";
import pagination from "@/mixins/Pagination.js";
import confimModel from "@/components/confimModel/index.vue";
const columns = [
  {
    dataIndex: "goodsNameCn",
    key: "goodsNameCn",
    title: "商品名称",
    ellipsis: true,
  },
  {
    title: "商品分类",
    dataIndex: "goodsCategoryName",
    key: "goodsCategoryName",
    ellipsis: true,
  },
  {
    title: "申请时间",
    dataIndex: "createTime",
    key: "createTime",
    ellipsis: true,
  },
  {
    title: "基础价",
    dataIndex: "onPrice",
    ellipsis: true,
  },
  {
    title: "定制起订量",
    dataIndex: "minOrder",
    ellipsis: true,
  },
  {
    title: "可售区域",
    dataIndex: "availableArea",
    ellipsis: true,
  },
  {
    title: "工厂名称",
    dataIndex: "factoryName",
    ellipsis: true,
  },
  {
    title: "操作",
    scopedSlots: { customRender: "operation" },
  },
];

const data = [];

export default {
  mixins: [pagination],
  data() {
    return {
      data,
      columns,
      examine: {
        visible: false,
        title: "详情",
      },
      loading: false,
      confirmConfig: {},
      reviewDescription: "",
      isDetect: false, // 是否检测
    };
  },
  components: {
    AddTableModel,
    MaxTexarea,
    confimModel,
  },
  methods: {
    async handleOperation(data) {
      let target = {
        GoodsId: data.goodsId,
      };
      let res = await SelectionGoods.getEditData(target);
      if (res.success) {
        this.examine.visible = true;
        this.examine.data = res.data;
        this.isDetect = res.data.isDetect;
        this.reviewDescription = res.data.reviewDescription;
        this.examine.type = "uninput";
      }
    },
    closeAdd() {
      this.examine.visible = false;
    },
    confirm() {},
    async getBaseData() {
      this.loading = true;
      let baseParmase = {
        page: this.pagination.current,
        size: this.pagination.pageSize,
        state: "2", //禁用
      };
      try {
        let res = await SelectionGoods.getBaseData(baseParmase);
        if (this.pagination.current > 1 && res.data.records.length == 0) {
          this.pagination.current -= 1;
          this.getBaseData();
        }
        this.pagination.total = res.data.total;
        this.data = setKey(res.data.records, "goodsId");
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    onPage(e) {
      this.pagination.current = e;
      this.getBaseData();
    },
    onSizeChange(current, size) {
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.getBaseData();
    },
    handleOk(data) {
      let obj = {
        url: `/mall/factory/startUsing/${data.goodsId}`,
        visible: true,
        method: "put",
        title: `确认要启用“${data.goodsNameCn}”吗？`,
        message: "启用成功",
      };
      this.confirmConfig = obj;
    },
  },
  mounted() {
    this.getBaseData();
  },
};
</script>
